/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS =
  'https://uxpam03odc.execute-api.ap-northeast-1.amazonaws.com/dev/userauthinfos/';
// ヘッダー項目生成
export const HEADER_LIST =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/headerlist/';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT =
  'https://uxpam03odc.execute-api.ap-northeast-1.amazonaws.com/dev/validationresult/';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT =
  'https://uxpam03odc.execute-api.ap-northeast-1.amazonaws.com/dev/multivalidationresult/';
// 画像登録
export const API_URL_UPLOAD_IMAGE =
  'https://uxpam03odc.execute-api.ap-northeast-1.amazonaws.com/dev/uploadimage/';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT =
  'https://uxpam03odc.execute-api.ap-northeast-1.amazonaws.com/dev/runcount/';

/**
 * TOP画面
 */
/** お知らせ */
// お知らせ確認
export const API_URL_INFORMATIONS2 =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/informations2';

/**
 * マスタ画面
 */
/** 販促資材マスタ */
// REST API(検索/登録/削除)
export const API_URL_ITEM =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/items/';
// 販促資材マスタ.入力画面
export const API_URL_INPUT_ITEM =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/inputitems/';
// 販促資材マスタ.検索画面
export const API_URL_SEARCH_ITEM =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/searchitems/';
// 販促資材マスタ.入庫登録、在庫調整
export const API_URL_STOCK =
  'https://xczzk6fo29.execute-api.ap-northeast-1.amazonaws.com/dev/stock/';

/** 届先マスタ */
// REST API(検索/登録/削除)
export const API_URL_DELIVERY =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/deliveries/';
// 届先マスタ.入力画面
export const API_URL_INPUT_DELIVERY =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/inputdeliveries/';
// 届先マスタ.検索画面
export const API_URL_SEARCH_DELIVERY =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/searchdeliveries/';

/** 営業日マスタ */
// REST API(検索/登録/削除)
export const API_URL_BIZDAY =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/bizdays/';
// 営業日マスタ.入力画面
export const API_URL_INPUT_BIZDAY =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/inputbizdays/';
// 営業日マスタ.検索画面
export const API_URL_SEARCH_BIZDAY =
  'https://8w8oid9zo1.execute-api.ap-northeast-1.amazonaws.com/dev/searchbizdays/';

/** ユーザマスタ */
// REST API(検索/登録/削除)
export const API_URL_USER =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/users/';
// ユーザマスタ.入力画面
export const API_URL_INPUT_USER =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/inputusers/';
// ユーザマスタ.検索画面
export const API_URL_SEARCH_USER =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/searchusers/';

/** 組織マスタ */
// REST API(検索/登録/削除)
export const API_URL_DEPARTMENT =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/departments/';
// 組織マスタ.入力画面
export const API_URL_INPUT_DEPARTMENT =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/inputdepartments/';
// 組織マスタ.検索画面
export const API_URL_SEARCH_DEPARTMENT =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/searchdepartments/';

/** お知らせマスタ */
// REST API(検索/登録/削除)
export const API_URL_INFORMATION =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/informations/';
// お知らせマスタ.入力画面
export const API_URL_INPUT_INFORMATION =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/inputinformations/';
// お知らせマスタ.検索画面
export const API_URL_SEARCH_INFORMATION =
  'https://8oimlm5b5e.execute-api.ap-northeast-1.amazonaws.com/dev/searchinformations/';

/**
 * 発注管理画面
 */
/** 発注管理.販促資材 */
// REST API(検索)
export const API_URL_ORDER_ITEM =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems/';
// 発注管理.販促資材.検索画面
export const API_URL_SEARCH_ORDER_ITEM =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/searchorderitems/';
// REST API(登録→発注管理.販促資材/削除→発注管理.選択リスト.販促資材)
export const API_URL_WORK_ORDER_ITEM =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/workorderitems/';

/** 発注管理.届先 */
// REST API(検索)
export const API_URL_ORDER_DELIVERIE =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/orderdeliveries/';
// 発注管理.届先.検索画面
export const API_URL_SEARCH_ORDER_DELIVERIE =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/searchorderdeliveries/';
// REST API(検索→発注管理.選択リスト.届先/登録→発注管理.届先/削除→発注管理.選択リスト.届先)
export const API_URL_WORK_ORDER_DELIVERIE =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/workorderdeliveries/';

/** 発注管理.選択リスト */
// 発注管理.選択リスト.販促資材
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/workitemstockinfo/';

/** 発注管理.数量 */
// REST API (検索→発注管理.数量/登録→発注管理.選択リスト/削除→発注管理.数量)
export const API_URL_WORK_ORDER =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/workorders/';
// REST API (検索→発注管理.注文履歴/登録→発注管理.数量)
export const API_URL_ORDER =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/orders/';
// 発注管理.数量.入力画面
export const API_URL_INPUT_WORK_ORDER =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/inputworkorders/';

/** 発注管理.注文履歴 */
// 発注管理.注文履歴.入力画面
export const API_URL_INPUT_ORDER =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/inputorders/';
// 発注管理.注文履歴.検索画面
export const API_URL_SEARCH_ORDER =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/searchorders/';
// 発注管理.注文履歴.詳細（発注者情報）*/
export const API_URL_USER_DEPARTMENT =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/userdepartments/';
// 発注管理.注文履歴.ステータス更新
export const API_URL_ORDER_STATUS =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/status';
// 発注管理.注文履歴.キャンセル
export const API_URL_ORDER_CANCEL =
  'https://8449lmgcl3.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/cancel/';
