/**
 * プロジェクトタイトル
 */
export const TITLE = {
  // タイトルバー
  TITLE_BAR: '未利用食品マッチング',
  // タイトルヘッダー
  TITLE_HEADER: '未利用食品マッチングシステム',
  // タイトルフッダー
  TITLE_FOOTER: '',
};

/**
 * auth0設定情報(検証/開発)clea
 */
export const environment_auth0 = {
  production: false,
  AUTH0_DOMAIN: 'tpn-hsj-dev.au.auth0.com', // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: 'pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4', // ApplicationsのClient ID(auth0情報)
  AUDIENCE: 'https://aws-jwt-authorizer', // APIsのAPI Audience(auth0情報)
  API_URL: 'https://*', // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(本番)
 */
/*
export const environment_auth0 = {
  production: false,
  AUTH0_DOMAIN: 'tpn-hsj-prod.jp.auth0.com', // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: 'vznjVb65ABD2uTT578UDzxGRmqdqjzvU', // ApplicationsのClient ID(auth0情報)
  AUDIENCE: 'https://unused-food-matching-authorizer', // APIsのAPI Audience(auth0情報)
  API_URL: 'https://*', // トークン付与対象APIエンドポイント
};
*/

/**
 * 国際化（i18n）設定
 */
export const environment_i18n = {
  production: false,
  defaultLang: 'ja',
};

/**
 * セッションキー
 */
export const SESSION_KEY = {
  // ログインユーザ情報
  loginUserInformation: 'loginUserInformation',

  // TODO ログアウト時のメッセージコード格納に使用　※auth0が間に入り、いいパラメータ受け渡し方法が見つかるまで使用
  // ログインアウト時メッセージコード
  loginOutMessageCode: 'loginOutMessageCode',
};
